
export default {
	name: "CoreBlockSelect",
	props: {
		options: {
			type: Array,
			required: true,
		},
		customClass: {
			type: String,
			default: null,
		},
		value: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		required: {
			type: Boolean,
			default: false,
		},
		disableFiltering: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isOpen: false,
			isTouched: false,
			isTyping: false,
			searchText: "",
			selectedOption: null,
			highlightedIndex: null,
		};
	},
	computed: {
		filteredOptions() {
			if (this.disableFiltering) {
				return this.options;
			}
			return this.options.filter((option) =>
				option.text
					.toLowerCase()
					.includes(this.searchText.toLowerCase())
			);
		},
	},
	watch: {
		value(newValue) {
			this.selectedOption =
				this.options.find((option) => option.value === newValue) ||
				null;
		},
		selectedOption(newValue) {
			if (newValue) {
				this.$emit("input", newValue.value);
			} else {
				this.$emit("input", null);
			}
		},
	},
	mounted() {
		window.addEventListener("click", this.closeDropdown);
	},
	beforeUnmount() {
		window.removeEventListener("click", this.closeDropdown);
	},
	methods: {
		toggleDropdown() {
			this.isOpen = !this.isOpen;
		},
		selectOption(option) {
			this.selectedOption = option;
			this.searchText = option.text; // Set the selected option's text to the input value
			this.isOpen = false;
		},
		handleInput() {
			this.isOpen = true;
			this.isTyping = true;
			this.highlightedIndex = 0;
		},
		blurHandler() {
			setTimeout(() => {
				if (this.searchText === "") {
					this.isTouched = true;
					this.$emit("input", "");
				} else {
					this.isOpen = false;
				}
				this.isTyping = false;
			}, 200);
		},
		closeDropdown(event) {
			if (this.isOpen && !this.$el.contains(event.target)) {
				this.isTyping = false;
				this.isOpen = false;

				const matchedOption = this.options.find(
					(o) =>
						o.text.toLowerCase() === this.searchText.toLowerCase()
				);
				if (!matchedOption) {
					this.searchText = "";
				} else {
					this.selectOption(matchedOption);
				}
			}
		},
		highlightOption(index) {
			this.highlightedIndex = index;
		},

		clearHighlightOption() {
			this.highlightedIndex = null;
		},

		isSelected(option) {
			return this.selectedOption === option;
		},
		handleKeyDown(event) {
			if (event.key === "ArrowUp") {
				this.isOpen = true;
				// Handle Arrow Up key
				event.preventDefault();
				if (this.highlightedIndex === null) {
					this.highlightedIndex = this.filteredOptions.length - 1;
				} else if (this.highlightedIndex === 0) {
					this.highlightedIndex = null;
				} else {
					this.highlightedIndex--;
				}
				this.scrollToHighlightedOption();
			} else if (event.key === "ArrowDown") {
				this.isOpen = true;
				// Handle Arrow Down key
				event.preventDefault();
				if (this.highlightedIndex === null) {
					this.highlightedIndex = 0;
				} else if (
					this.highlightedIndex ===
					this.filteredOptions.length - 1
				) {
					this.highlightedIndex = null;
				} else {
					this.highlightedIndex++;
				}
				this.scrollToHighlightedOption();
			} else if (
				event.key === "Enter" &&
				this.highlightedIndex !== null
			) {
				this.isOpen = true;
				// Handle Enter key
				event.preventDefault();
				const selectedOption =
					this.filteredOptions[this.highlightedIndex];
				this.selectOption(selectedOption);
			} else if (event.key === "Tab" && this.isOpen) {
				this.isOpen = false;

				if (this.filteredOptions.length === 0) {
					this.searchText = "";
					this.$emit("input", "");
				} else if (
					this.highlightedIndex !== null &&
					this.filteredOptions.length > 0
				) {
					const selectedOption =
						this.filteredOptions[this.highlightedIndex];
					this.selectOption(selectedOption);
				} else {
					const matchedOption = this.filteredOptions.find(
						(o) =>
							o.text.toLowerCase() ===
							this.searchText.toLowerCase()
					);
					if (matchedOption) {
						this.selectOption(matchedOption);
					} else {
						this.searchText = "";
						this.$emit("input", "");
					}
				}
			}
		},
		scrollToHighlightedOption() {
			if (!this.$refs.dropdownList || this.highlightedIndex === null) {
				return;
			}

			const listElement = this.$refs.dropdownList;
			const itemElement = listElement.querySelector(
				`li:nth-child(${this.highlightedIndex + 1})`
			);

			if (!itemElement) {
				return;
			}

			const itemOffsetTop = itemElement.offsetTop;
			const itemOffsetBottom = itemOffsetTop + itemElement.offsetHeight;
			const listScrollTop = listElement.scrollTop;
			const listScrollBottom = listScrollTop + listElement.offsetHeight;

			if (itemOffsetTop < listScrollTop) {
				// Scroll up to make the highlighted option visible at the top
				listElement.scrollTop = itemOffsetTop;
			} else if (itemOffsetBottom > listScrollBottom) {
				// Scroll down to make the highlighted option visible at the bottom
				listElement.scrollTop =
					itemOffsetBottom - listElement.offsetHeight;
			}
		},
	},
};
